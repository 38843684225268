import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

import GUI from "lil-gui";

import { getRaf } from "@app";
import { easeInOutQuint } from "/utils/easing.js";
import clamp from "/utils/clamp.js";
import map from "/utils/map.js";

import fragment from "./shader/fragment.glsl";
import vertex from "./shader/vertex.glsl";

import { DotScreenShader } from "./CustomShader";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

import "./style.css";
export default class Canvas {
  static selector = "#canvas";

  constructor(block) {
    this.block = block;

    this.progress = 1;

    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.mousePosition = {
      x: 0,
      y: 0,
    };

    this.background = {
      color1: [230 / 255, 230 / 255, 230 / 255],
      color2: [246 / 255, 246 / 255, 246 / 255],
      accent: [255 / 255, 255 / 255, 255 / 255],
      speed: 0.004,
      speed: 0.004,
      p1: 0.754,
      p2: 3.904,
      o1: 0.1,
      o2: 0.5,
      positionX: 0,
      positionY: 0,
      positionZ: 0,
      noise: 0.4,
    };

    //Scene
    this.scene = new THREE.Scene();

    // Camera
    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );
    this.camera.position.set(0, 0, 1.3);

    // Controls
    this.controls = new OrbitControls(this.camera, this.block);

    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.block,
      antialias: true,
      alpha: true,
    });
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.windowSizes.width, this.windowSizes.height);
    this.renderer.setClearColor(0xeeeeee, 1);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.time = 0;
  }

  setupSettings = () => {
    this.gui = new GUI();
    const background = this.gui.addFolder("Background");
    background
      .addColor(this.background, "color1")
      .name("color 1")
      .onChange((value) => {
        this.material.uniforms.color1.value = value;
      });

    background
      .addColor(this.background, "color2")
      .name("color 2")
      .onChange((value) => {
        this.material.uniforms.color2.value = value;
      });

    background
      .addColor(this.background, "accent")
      .name("accent color")
      .onChange((value) => {
        this.material.uniforms.accent.value = value;
      });

    background
      .add(this.background, "speed", 0.001, 0.1, 0.001)
      .name("speed")
      .onChange((value) => {
        this.background.speed = value;
      });

    background
      .add(this.background, "p1", 0.001, 1, 0.001)
      .name("p1")
      .onChange((value) => {
        this.material.uniforms.p1.value = value;
      });

    background
      .add(this.background, "p2", 0, 50, 0.001)
      .name("p2")
      .onChange((value) => {
        this.material.uniforms.p2.value = value;
      });

    background
      .add(this.background, "o1", 0, 10, 0.001)
      .name("o1")
      .onChange((value) => {
        this.material.uniforms.o1.value = value;
      });

    background
      .add(this.background, "o2", 0, 10, 0.001)
      .name("o2")
      .onChange((value) => {
        this.material.uniforms.o2.value = value;
      });

    background
      .add(this.background, "positionX", 0, 100, 0.001)
      .name("positionX")
      .onChange((value) => {
        this.camera.position.setX(value);
      });

    background
      .add(this.background, "positionY", 0, 100, 0.001)
      .name("positionY")
      .onChange((value) => {
        this.camera.position.setY(value);
      });

    background
      .add(this.background, "positionZ", 0, 10, 0.001)
      .name("positionZ")
      .onChange((value) => {
        this.camera.position.setZ(value);
      });

    background
      .add(this.background, "noise", 0, 100, 0.001)
      .name("noise")
      .onChange((value) => {
        this.noiseEffect.uniforms.noise.value = value;
      });
  };

  setPost = () => {
    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));

    this.noiseEffect = new ShaderPass(DotScreenShader);
    this.noiseEffect.uniforms["scale"].value = 4;
    this.composer.addPass(this.noiseEffect);
  };

  addObjects = () => {
    this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
      format: THREE.RGBAFormat,
      generateMipmaps: true,
      minFilter: THREE.LinearMipMapLinearFilter,
      encoding: THREE.sRGBEncoding,
    });

    this.cubeCamera = new THREE.CubeCamera(0.1, 10, this.cubeRenderTarget);

    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: this.background.speed },
        resolution: { value: new THREE.Vector4() },
        color1: { value: this.background.color1 },
        color2: { value: this.background.color2 },
        accent: { value: this.background.accent },
        p1: { value: this.background.p1 },
        p2: { value: this.background.p2 },
        o1: { value: this.background.o1 },
        o2: { value: this.background.o2 },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    this.geometry = new THREE.SphereBufferGeometry(1.5, 32, 32);

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
  };

  render = () => {
    this.time += this.background.speed;
    this.cubeCamera.update(this.renderer, this.scene);
    this.material.uniforms.time.value = this.time;
    //this.renderer.render(this.scene, this.camera);
    this.composer.render(this.scene, this.camera);

    this.mesh.rotation.x = THREE.MathUtils.lerp(
      this.mesh.rotation.x,
      (this.mousePosition.y * Math.PI) / 20,
      0.05
    );

    this.mesh.rotation.y = THREE.MathUtils.lerp(
      this.mesh.rotation.y,
      (this.mousePosition.x * Math.PI) / 20,
      0.09
    );
  };

  addEventListeners = () => {
    window.addEventListener("mousemove", this.onMouseMove);
  };

  onMouseMove = (e) => {
    this.mousePosition.x = map(
      e.clientX - this.windowSizes.width / 2,
      -this.windowSizes.width / 2,
      this.windowSizes.width / 2,
      -2,
      2
    );
    this.mousePosition.y = map(
      e.clientY - this.windowSizes.height / 2,
      -this.windowSizes.height / 2,
      this.windowSizes.height / 2,
      -2,
      2
    );
  };

  onResize = () => {
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.renderer.setSize(this.windowSizes.width, this.windowSizes.height);

    this.composer.setSize(this.windowSizes.width, this.windowSizes.height);

    this.camera.aspect = this.windowSizes.width / this.windowSizes.height;
    this.camera.updateProjectionMatrix();
  };

  onReady = () => {
    this.addObjects();
    this.setPost();
    this.onResize();
    this.setupSettings();
    this.addEventListeners();
  };

  onComplete = () => {
    const raf = getRaf();
    raf.register("canvas", this.render);
  };
}
